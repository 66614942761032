<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card outlined>
      <v-card-text v-if="result.today_statistics">
        <div class="text-Heading6 Medium ma-3">今日統計資料</div>
        <v-row>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>總成交額</div>
                  <div>{{ result.today_statistics.total_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>訂單總數</div>
                  <div>{{ result.today_statistics.order_count }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>凍結金額</div>
                  <div>{{ result.today_statistics.frozen_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>代理提成</div>
                  <div>{{ result.today_statistics.agent_fee_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>利潤總額</div>
                  <div>{{ result.today_statistics.profit_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>解凍金額</div>
                  <div>{{ result.today_statistics.unfrozen_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="result.query_statistics">
        <div class="text-Heading6 Medium ma-3 primary--text">
          {{ filter.report_date[0] | formatTime }} ~
          {{ filter.report_date[1] | formatTime }} 統計資料
        </div>
        <v-row>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>總成交額</div>
                  <div>{{ result.query_statistics.total_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>訂單總數</div>
                  <div>{{ result.query_statistics.order_count }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>凍結金額</div>
                  <div>{{ result.query_statistics.frozen_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>代理提成</div>
                  <div>{{ result.query_statistics.agent_fee_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>利潤總額</div>
                  <div>{{ result.query_statistics.profit_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card outlined color="Surface100">
              <v-card-text class="d-flex">
                <v-divider vertical class="mr-2"></v-divider>
                <div>
                  <div>解凍金額</div>
                  <div>{{ result.query_statistics.unfrozen_amt }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
          :disabled="!$getGrantValue('platform_order_reports.export')"
        />
      </v-card-text>
      <DataTable
        :loading="isLoading"
        :list="result.list"
        :total="result.total"
        :fields="headers"
        @get-list="fetchData"
      ></DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      no-info
    />
  </div>
</template>

<script>
import { getPlatformOrderReport } from '@/api/statistics';

export default {
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '日期',
          value: 'report_date',
          align: 'center',
          width: '180px'
        },
        {
          text: '總提交訂單',
          value: 'total_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '總已付訂單',
          value: 'total_paid_collect_order',
          align: 'center',
          width: '104px'
        },

        {
          text: '總未付訂單',
          value: 'total_unpaid_collect_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '提交總額',
          value: 'total_collect_order_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '總用戶入帳',
          value: 'total_collect_order_credit_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代收總手續費',
          value: 'total_collect_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '代理提成',
          value: 'total_agent_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '當日用戶資金',
          value: 'merchant_capital_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '凍結資金總額',
          value: 'total_frozen_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付總額',
          value: 'total_pay_order_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付總手續費',
          value: 'total_pay_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '總到帳金額',
          value: 'total_pay_order_actual_amt',
          align: 'center',
          width: '104px'
        }
      ],
      filterOptions: [
        {
          label: '交易時間',
          type: 'timeSet',
          name: 'report_date',
          range: true,
          withTime: true
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const Data = await getPlatformOrderReport({
        ...this.filter,
        ...condition
      });
      if (!Data.error) {
        this.result.total = Data.total;
        this.result.page = Data.page;
        this.result.today_statistics = Data.today_statistics;
        if (Data.query_statistics) {
          this.result.query_statistics = Data.query_statistics;
        } else {
          this.result.query_statistics = null;
        }
        this.$set(
          this.result,
          'list',
          Data.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    },

    handleAction(fn) {
      fn(this);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
